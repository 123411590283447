import React from 'react'
import { compose, pure } from 'recompose'
import { withNamespaces } from 'react-i18next'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'

import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import EnvironnementalementResponsable from '../../components/assets/EnvironnementalementResponsable'

const EnvironnementalementResponsablePage = ({ t }) => (
  <SecondaryLayout subtitle={t('titre')} page="ComponentAssetsEnvironnementalementResponsable">
    <EnvironnementalementResponsable />
  </SecondaryLayout>
)

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('environnementalement-responsable')
)

export default echance(EnvironnementalementResponsablePage)
