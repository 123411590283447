import React, { Component } from 'react'
import { MaterialSvgIcon as SvgIcon } from 'inno-components'

const Delivery = props => (
  <SvgIcon {...props} viewBox="0 0 24 24" width="1em" height="1em">
    <g>
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path d="M12.01 6c2.61 0 4.89 1.86 5.4 4.43l.3 1.5 1.52.11c1.56.11 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3h-13c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.95 6 12.01 6m0-2C9.12 4 6.6 5.64 5.35 8.04 2.35 8.36.01 10.91.01 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.64-4.96C18.68 6.59 15.65 4 12.01 4z" />
    </g>
  </SvgIcon>
)

export default Delivery
