import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { StyleSheet, css } from 'aphrodite'
import { Typicons } from 'inno-components/build/Icons'
import { scroller, Element } from 'react-scroll'
import Helmet from 'react-helmet'
import { Section } from 'inno-components'

import Config from '../../Config'
import Classes from '../../styles/classes'

import IconSection from '../../icons/IconSection'
import EarthIcon from '../../icons/Earth'
import CloudyIcon from '../../icons/Cloudy'

const styles = StyleSheet.create({
  sectionTop: {
    backgroundColor: Config.colors.quaternary
  },
  sectionWhite: {
    backgroundColor: '#FFFFFF'
  },
  grid: {
    margin: 0
  },
  container: {
    minHeight: '300px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    overflowY: 'auto'
  },
  icon: {
    fontSize: '1.3em',
    color: '#FFFFFF'
  }
})
class EnvironnementalementResponsable extends Component {
  onSectionClick = (section) => {
    scroller.scrollTo(section, {
      smooth: true
    })
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        <Helmet
          title={t('head.title')}
          meta={[
            {
              name: 'description',
              content: t('head.meta-description')
            }
          ]}
        />
        <Section className={css(styles.sectionTop)}>
          <div className={css(styles.container)}>
            <IconSection
              title={t('menu.pollution')}
              height={170}
              fill={Config.colors.primary}
              direction="down"
              onClick={() => this.onSectionClick('pollution')}
            >
              <CloudyIcon className={css(styles.icon)} />
            </IconSection>
            <IconSection
              title={t('menu.dechets')}
              height={185}
              fill={Config.colors.secondary}
              direction="down"
              onClick={() => this.onSectionClick('dechets')}
            >
              <Typicons.TiTrash className={css(styles.icon)} />
            </IconSection>
            <IconSection
              title={t('menu.eau')}
              height={160}
              fill={Config.colors.thirdary}
              direction="down"
              onClick={() => this.onSectionClick('eau')}
            >
              <EarthIcon className={css(styles.icon)} />
            </IconSection>
          </div>
        </Section>
        <Element name="pollution" id="pollution">
          <Section className={css(Classes.section)}>
            <h2 className={css(Classes.h2)}>{t('sections.0.titre')}</h2>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.0')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.1')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.2')
              }}
            />
          </Section>
        </Element>
        <Element name="dechets" id="dechets">
          <Section className={css(Classes.section, styles.sectionWhite)}>
            <h2 className={css(Classes.h2)}>{t('sections.1.titre')}</h2>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.0')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.1')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.2')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.3')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.4')
              }}
            />
            <p
              className={css(Classes.paragraph, Classes.bold)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.5')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.6')
              }}
            />
          </Section>
        </Element>
        <Element name="eau" id="eau">
          <Section className={css(Classes.section)}>
            <h2 className={css(Classes.h2)}>{t('sections.2.titre')}</h2>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.0')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.2')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.3')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.4')
              }}
            />
          </Section>
        </Element>
      </React.Fragment>
    )
  }
}

export default withNamespaces('environnementalement-responsable')(EnvironnementalementResponsable)
