import React, { Component } from 'react'
import { MaterialSvgIcon as SvgIcon } from 'inno-components'

const Delivery = props => (
  <SvgIcon {...props} viewBox="-24 0 496 496" width="1em" height="1em">
    <path
      fill="#ffffff"
      d="m416 80c-17.648438 0-32 14.351562-32 32v101.230469l-16.65625 21.195312c-2.511719-10.554687-12.023438-18.425781-23.34375-18.425781h-1.046875c-6.632813 0-13.023437 2.785156-17.546875 7.625l-98.644531 105.695312c-1.042969 1.113282-1.867188 2.367188-2.761719 3.574219-.894531-1.207031-1.71875-2.460937-2.761719-3.574219l-98.644531-105.695312c-4.523438-4.839844-10.914062-7.625-17.546875-7.625h-1.046875c-11.320312 0-20.832031 7.871094-23.34375 18.433594l-16.65625-21.203125v-101.230469c0-17.648438-14.351562-32-32-32s-32 14.351562-32 32v136c0 1.335938.335938 2.65625.976562 3.832031l48 88c.367188.664063.832032 1.289063 1.367188 1.824219l62.632812 62.632812c4.464844 4.464844 7.023438 10.648438 7.023438 16.96875v66.742188c0 4.414062 3.585938 8 8 8h192c4.414062 0 8-3.585938 8-8v-66.742188c0-6.320312 2.558594-12.496093 7.023438-16.96875l62.632812-62.632812c.535156-.535156 1-1.160156 1.367188-1.824219l48-88c.640624-1.175781.976562-2.496093.976562-3.832031v-136c0-17.648438-14.351562-32-32-32zm-291.710938 312.976562-61.816406-61.816406-46.472656-85.199218v-133.960938c0-8.824219 7.175781-16 16-16s16 7.175781 16 16v104c0 1.792969.601562 3.527344 1.710938 4.945312l88 112 12.578124-9.882812-54.289062-69.101562v-13.960938c0-4.40625 3.59375-8 8-8h1.046875c2.210937 0 4.335937.929688 5.847656 2.535156l98.65625 105.695313c4.152344 4.464843 6.449219 10.28125 6.449219 16.386719v91.382812h-80v-26.742188c0-10.691406-4.160156-20.730468-11.710938-28.28125zm11.710938 71.023438h80v16h-80zm96 16v-16h80v16zm200-234.039062-46.472656 85.191406-61.816406 61.816406c-7.550782 7.558594-11.710938 17.597656-11.710938 28.289062v26.742188h-80v-91.382812c0-6.105469 2.296875-11.921876 6.457031-16.378907l98.65625-105.695312c1.503907-1.613281 3.628907-2.542969 5.839844-2.542969h1.046875c4.40625 0 8 3.59375 8 8v13.960938l-54.289062 69.09375 12.578124 9.882812 88-112c1.109376-1.410156 1.710938-3.144531 1.710938-4.9375v-104c0-8.824219 7.175781-16 16-16s16 7.175781 16 16zm0 0"
    />
    <path
      fill="#ffffff"
      d="m224 256c70.574219 0 128-57.425781 128-128s-57.425781-128-128-128-128 57.425781-128 128 57.425781 128 128 128zm0-16c-58.121094 0-106.015625-44.511719-111.457031-101.230469l18.769531-18.769531h12.6875c2.121094 0 4.160156-.839844 5.65625-2.34375l13.65625-13.65625h12.6875c2.121094 0 4.160156-.839844 5.65625-2.34375l32-32c1.503906-1.496094 2.34375-3.535156 2.34375-5.65625v-12.6875l13.65625-13.65625c1.503906-1.496094 2.34375-3.535156 2.34375-5.65625v-15.59375c14.238281 1.019531 27.71875 4.699219 40 10.554688v17.726562l-13.65625 13.65625c-1.503906 1.496094-2.34375 3.535156-2.34375 5.65625v12.6875l-13.65625 13.65625c-1.503906 1.496094-2.34375 3.535156-2.34375 5.65625v16c0 2.121094.839844 4.160156 2.34375 5.65625l13.65625 13.65625v12.6875c0 2.121094.839844 4.160156 2.34375 5.65625l16 16c1.496094 1.503906 3.535156 2.34375 5.65625 2.34375h12.6875l23.480469 23.480469c-20.222657 29.273437-53.976563 48.519531-92.167969 48.519531zm112-112c0 17.648438-4.214844 34.296875-11.519531 49.167969l-22.824219-22.824219c-1.496094-1.503906-3.535156-2.34375-5.65625-2.34375h-12.6875l-11.3125-11.3125v-12.6875c0-2.121094-.839844-4.160156-2.34375-5.65625l-13.65625-13.65625v-9.375l13.65625-13.65625c1.503906-1.496094 2.34375-3.535156 2.34375-5.65625v-12.6875l13.65625-13.65625c1.503906-1.496094 2.34375-3.535156 2.34375-5.65625v-11.785156c28.976562 20.265625 48 53.824218 48 91.785156zm-120-111.59375v12.28125l-13.65625 13.65625c-1.503906 1.496094-2.34375 3.535156-2.34375 5.65625v12.6875l-27.3125 27.3125h-12.6875c-2.121094 0-4.160156.839844-5.65625 2.34375l-13.65625 13.65625h-12.6875c-2.121094 0-4.160156.839844-5.65625 2.34375l-9.679688 9.679688c5.710938-53.511719 49.289063-95.765626 103.335938-99.617188zm0 0"
    />
    <path
      fill="#ffffff"
      d="m240 164.6875v-20.6875c0-2.121094-.839844-4.160156-2.34375-5.65625l-16-16c-1.496094-1.503906-3.535156-2.34375-5.65625-2.34375h-16c-2.121094 0-4.160156.839844-5.65625 2.34375l-13.65625 13.65625h-28.6875c-4.414062 0-8 3.585938-8 8v40c0 4.414062 3.585938 8 8 8h12.6875l13.65625 13.65625c1.496094 1.503906 3.535156 2.34375 5.65625 2.34375h12.6875l13.65625 13.65625c1.496094 1.503906 3.535156 2.34375 5.65625 2.34375h16c2.121094 0 4.160156-.839844 5.65625-2.34375l16-16c1.503906-1.496094 2.34375-3.535156 2.34375-5.65625v-16c0-2.121094-.839844-4.160156-2.34375-5.65625zm0 32-11.3125 11.3125h-9.375l-13.65625-13.65625c-1.496094-1.503906-3.535156-2.34375-5.65625-2.34375h-12.6875l-13.65625-13.65625c-1.496094-1.503906-3.535156-2.34375-5.65625-2.34375h-8v-24h24c2.121094 0 4.160156-.839844 5.65625-2.34375l13.65625-13.65625h9.375l11.3125 11.3125v20.6875c0 2.121094.839844 4.160156 2.34375 5.65625l13.65625 13.65625zm0 0"
    />
  </SvgIcon>
)

export default Delivery
